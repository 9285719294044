<template>
    <div class="t-page">
      <div class="t-body">
        <!-- <div class="filter">
          <div class="option" v-for="(i, index) in trainingFilterLabel" :key="index">
            <div class="filter-title"><span>{{ i.name }}</span></div>
            <div class="type-list">
              <div v-for="(item, index) in filters[i.name]" :key="index" class="item" :class="{'actived':selectedData.filter.includes(item.value)}" @click="selected(i.name, item.value, index)"><span>{{ item.label }}</span></div>
            </div>
          </div>
          <div class="search">
            <div class="count">Results Found <span>{{ count }}</span></div>
            <div class="search-bar">
              <input type="text" v-model="title" placeholder="Enter Keyword"/>
              <div class="search-btn" @click="getVideosByTitle"><i class="el-icon-search"></i></div>
            </div>
          </div>
        </div> -->
  
        <div class="csp-list">
          <div class="csp-item" v-for="(item, index) in dataList" :key="index">
            <img @click="toPage(item.id)" :src="item.coverImg" alt=""/>
            <div class="item-info">
              <p class="title">{{ item.title }}</p>
              <div class="pro">
                <span>{{ item.createTime }}</span> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import { getCourseById, getCourseList } from '@/api/myLearnApi'
export default {
name: 'MyLearn',
data() {
    return {
        dataList: []
    }
},
mounted() {
    this.getCourseList()
},
methods: {
    toPage(id) {
        this.$router.push({
            path: `/my-learn/${id}/details`
        })
    },
    async getCourseList() {
        const { data: {data, isSuccess} } = await getCourseList()
        if (isSuccess) {
            console.log(data)
            this.dataList = data
        }
    }
}
}
</script>

<style lang="less" scoped>
  .t-page {
    min-height: inherit;
    background-color: #f0f0f0;
    padding-top: 20px;
    .t-body {
      width: 70%;
      margin: auto;
    }
  }
    .filter {
      width: 100%;
      padding: 0 15px;
      background-color: #fff;
      .option {
        display: flex;
        padding: 10px 0;
        border-bottom: 1px solid rgba(128, 128, 128, 0.192);
        &+.option {
          // margin-top: 12px;
        }
        .filter-title {
          flex-shrink: 0;
          padding: 4px 0px;
          font-weight: bold;
          width: 150px;
        }
        .type-list {
          margin-left: 30px;
          .item {
            display: inline-block;
            padding: 4px 6px;
            border: 1px solid transparent;
            cursor: pointer;
            margin-right: 6px;
            // border-radius: 5px;
            &:hover {
              color: rgb(199, 0, 11);
              border: 1px solid rgb(199, 0, 11);
            }
            // &+.item {
            //   margin-left: 20px;
            // }
          }
          .actived {
            color: rgb(199, 0, 11);
            border: 1px solid rgb(199, 0, 11);
          }
        }
      }
      .search {
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .count {
          margin-right: 30px;
          span {
            color: rgba(199,0,11,0.76);
          }
        }
        .search-bar {
          display: flex;
          height: 30px;
          input {
            outline: 0;
            width: 204px;
            height: 30px;
            padding: 2px 10px;
            border: 1px solid #CCCCCC;
            border-radius: 2px 0 0 2px;
            border-right: none;
          }
          .search-btn {
            height: 30px;
            width: 30px;
            text-align: center;
            line-height: 30px;
            background-color: rgba(199,0,11,0.76);
            cursor: pointer;
            i {
              height: 30px;
              width: 30px;
              line-height: 30px;
              color: #ffffff;
              font-size: 18px;
            }
          }
        }
      }
    }
    .csp-list {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      
      .csp-item {
        background-color: #fff;
        width: calc(100% / 4 - 12px);
        display: inline-block;
        margin-bottom: 20px;
        &:not(:nth-child(4n)) {
          margin-right: 16px;
        }
        img {
          width: 100%;
          height: 155.41px;
          cursor: pointer;
        }
        .item-info {
          padding: 0 10px;
          padding-bottom: 10px;
          .title {
            padding: 5px 0;
            font-weight: bold;
            /* 设置文本不换行 */
            // white-space: nowrap;
            /* 设置多余文本以省略号的形式出现 */
            // text-overflow: ellipsis;
            // overflow: hidden;
          }
          .descript {
            // padding-bottom: 10px;
            font-size: 12px;
            color: #a7a7a7;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: break-all;
            height: 34.28px;
          }
          .pro{
            color: #a7a7a7;
            font-size: 12px;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  </style>