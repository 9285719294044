import axiosApi from '@/api/axiosApi'

export const getCourseById = (data) => {
    return axiosApi({
        method: 'POST',
        url: `/intserv/my-lear/getCourseById`,
        data
    })
}

export const getMenuById = (data) => {
    return axiosApi({
        method: 'POST',
        url: `/intserv/my-lear/getMenuById`,
        data
    })
}

export const getCourseList = () => {
    return axiosApi({
        method: 'GET',
        url: `/intserv/my-lear/getCourseList`
    })
}